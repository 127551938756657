import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function toggleNav($) {



    // Add Down Arrow Icon In Nav =================

    var subMenu = $('header .header .navs > ul > li > .sub-menu');
    $(subMenu).prev('a').addClass('down-arrow');


    // Add Class Ative On Header On Scrolling ==============================

    $(window).scroll(function () {
        if ($(window).scrollTop() > 140) {
            $('header').addClass('active');
        }
        else {
            $('header').removeClass('active');
        }
    });



    // Side-menu Area ==============================

    var MenuBtn = $('header .header .menu-btn-box');
    var sideMenu = $('.side-menu-area');
    var sideMenu_overlay = $('.side-menu-area .overlay');
    var sideMenu_close = $('.side-menu .header .close-btn');

    $(MenuBtn).click(function () {
        $(sideMenu).addClass('active');
        $('body').css({ 'overflow': 'hidden' });
    });

    $(sideMenu_overlay).click(function () {
        $(sideMenu).removeClass('active');
        $('body').css({ 'overflow': '' });
    });

    $(sideMenu_close).click(function () {
        $(sideMenu).removeClass('active');
        $('body').css({ 'overflow': '' });
    });




    // Side-menu Drop-Down ==============================

    $('.side-menu .navs #slide11').click(function () {
        setTimeout(function () {
            $(sideMenu).removeClass('active');
        }, 1000)
        $('body').css({ 'overflow': '' });
    })

    var sideMenu_subMenu = $('.side-menu .navs > ul > li > .sub-menu');
    $(sideMenu_subMenu).prev('a').addClass('down-arrow');
    // if( $(this).next('.sub-menu').slideUp()){
    var newone = true
    var newone1 = true
    $('.side-menu .navs > ul > li > a').click(function () {
        // $('.side-menu .navs > ul > li > a ~ .sub-menu').slideUp();
        // $('.side-menu .navs > ul > li > a').removeClass('active');
        // if ($(this).next('.sub-menu').is(':visible')) {
        //     $('.side-menu .navs > ul > li > a').removeClass('active');
        //     $(this).next('.sub-menu').slideUp();,
        // }
        // else {
        // if(newone == true){
        // $(this).next('.sub-menu').slideToggle();
        switch (newone1) {
            case (newone): $(this).next('.sub-menu').slideDown();
                $(this).addClass('active');
                newone = false
                break;
            case (newone == false): $(this).next('.sub-menu').slideUp();
                $(this).removeClass('active');
                newone = true
                break;
            default: console.log(newone);

        }
    });





    // Request-Form Popup ==============================
    var requestBtn_1 = $('header .header .btns-box .request-btn button');
    var requestBtn_2 = $('.side-menu .request-btn button');
    var requestForm_popup = $('.request-form-popup');
    var requestForm_close = $('.request-form .header .close-btn button');
    var requestForm_overlay = $('.request-form-popup .overlay');

    $(requestBtn_1).click(function () {
        $(requestForm_popup).addClass('active');
        $('body').css({ 'overflow': 'hidden' });
    });

    $(requestBtn_2).click(function () {
        $(requestForm_popup).addClass('active');
        $('body').css({ 'overflow': 'hidden' });
    });

    $(requestForm_close).click(function () {
        $(requestForm_popup).removeClass('active');
        $('body').css({ 'overflow': '' });
    });

    $(requestForm_overlay).click(function () {
        $(requestForm_popup).removeClass('active');
        $('body').css({ 'overflow': '' });
    });

    $(requestBtn_2).click(function () {
        $(sideMenu).removeClass('active');
        $('body').css({ 'overflow': '' });
    });
}
const jquery = window.jQuery;
function Header() {
    useEffect(() => {

        addTitleAndDescription();
        toggleNav(jquery)
    }, [])
    const [title, setTitle] = useState("");
    const [description, setDescription] =  useState("");
    const addTitleAndDescription = () => {
        const titleJson = {
            "home":{
                "title":"Medyseva Best Rural Healthcare Partner | Telemedicine in Rural India",
                "description":"Medyseva connects rural patients with top urban doctors via telemedicine. Get affordable healthcare services, from diagnostics to surgeries, at our Medyseva Kendras."
            },    
            "medymate-healthcare-for-Schools-Colleges":{
                "title":"MedyMate by Medyseva: Comprehensive Health Care for Schools & Colleges",
                "description":"MedyMate offers tailored healthcare solutions for 100+ schools, colleges, and hostels. Ensure the well-being of your students and staff with our comprehensive health services designed for educational institutions."
            },
            "medytalk-health-webinars-discussions":{
                "title":"MedyTalk: 50+ Health Webinars Covering Key Medical Topics",
                "description":"Join MedyTalk's expert-led health webinars and discussions. Stay informed on essential medical topics and connect with top healthcare professionals for valuable insights."
            },
            "terms-and-conditions":{
                "title":"Medyseva Terms and Conditions | Your Guide to Using Our Services",
                "description":"Explore Medyseva's Teleconsultation Terms & Conditions. Learn about user conduct, payment security, privacy policies, and the guidelines for using our healthcare platform."
            },
            "cancellation-and-refund-policy":{
                "title":"Medyseva Refund & Cancellation Policy | Best Teleconsultation Services",
                "description":"Review Medyseva's cancellation and refund policy. Although refunds are not issued, you can reschedule your teleconsultation by contacting our support team."
            },
            "shipping-policy":{
                "title":"Medyseva Shipping Policy | Access Healthcare Services Digitally",
                "description":"Review Medyseva's shipping policy. Enjoy instant digital delivery of teleconsultation services with no shipping charges. Access healthcare anytime, anywhere in India."
            },
            "news-and-updates":{
                "title":"Medyseva Shipping Policy | Access Healthcare Services Digitally",
                "description":"Review Medyseva's shipping policy. Enjoy instant digital delivery of teleconsultation services with no shipping charges. Access healthcare anytime, anywhere in India."
            },
            "blog":{
                "title":"Medyseva Blog",
                "description":"blog"
            }
        }
        
        var parts = window.location.href;
        var segments = parts.split('/');
        var titleDescriptionObject = "";
        if(segments[3] !== undefined && segments[3] !== null && segments[3] !== ""){
            titleDescriptionObject = titleJson[segments[3]];
        }else{
            titleDescriptionObject = titleJson["home"];
        }
        if(titleDescriptionObject === undefined){
            titleDescriptionObject = titleJson["home"];
        }
        
        setTitle(titleDescriptionObject["title"]);
        setDescription(titleDescriptionObject["description"])
        document.title=titleDescriptionObject["title"]
    
    }
    //on different page//
    const navigate = useNavigate();

    const handleRoutingOnDiffPage = (e) => {
        let path = window.location.pathname;
        let hash = e.target.hash;
        if (path === "/") {
            console.log(
                "dont prevent default here and navigate to specified section"
            );
        } else {
            if (hash !== undefined || hash !== "") {
                navigate(`/${hash}`);
            } else {
                navigate(`/`);
            }
        }
    };


    const [dorpList, setdorpList] = useState(false);
    const handleDropList = () => {
        setdorpList(!dorpList);
    }
    const [pathList, setPathList] = useState(["/privacy-policy", "/medytalk-health-webinars-discussions", "/terms-and-conditions"]);
    const getNoIndexMeta = () =>{
        var path = ["privacy-policy", "medytalk", "tc"];
        return path.includes(window.location.pathname) ?"<meta name='robots' content='noindex'>" : "";
    }
    
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <link rel="canonical" href={window.location.href} />
                {(pathList.includes(window.location.pathname) ? (<meta name="robots" content="noindex"/>):null)}
                <meta name="description" content={description} />
            </Helmet>
            <header>
                <div className="container">
                    <div className="header">
                        <div className="logo">
                            <a href="/">
                                <img src="assets/img/logo/logo.png" alt="logo" className="logo-img-1" />
                            </a>
                        </div>
                        <div className="navs">
                            <ul>
                                {/* <li>
                                    <a onClick={handleRoutingOnDiffPage} href="/#about">About Us</a>
                                </li> */}

                                <li>
                                    <a href="#">About Us</a>
                                    <div className="sub-menu">
                                        <ul>
                                            <li>
                                                <a onClick={handleRoutingOnDiffPage} href="/#gallery">Gallery</a>
                                            </li>
                                            <li>

                                                <a onClick={handleRoutingOnDiffPage} href="/#team">Team</a>
                                            </li>
                                            <li>
                                                <NavLink className="nav_news" to="/news-and-updates" >News/Events</NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="nav_news" to="/blog" >Our BLog</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="#">Services</a>
                                    <div className="sub-menu">
                                        <ul>
                                            <li>
                                                <a onClick={handleRoutingOnDiffPage} href="#services">Consultation</a>
                                            </li>
                                            <li>
                                                <a onClick={handleRoutingOnDiffPage} href="#services ">Pathology</a>
                                            </li>
                                            <li>
                                                <a onClick={handleRoutingOnDiffPage} href=" #services">Radiology</a>
                                            </li>
                                            <li>
                                                <a onClick={handleRoutingOnDiffPage} href=" #services">Hospitalization</a>
                                            </li>
                                            <li>
                                                <a onClick={handleRoutingOnDiffPage} href=" #services">Ambulance</a>
                                            </li>

                                        </ul>
                                    </div>
                                </li>
                                {/* <li>
                                    <a onClick={handleRoutingOnDiffPage} href="/#team">Team</a>
                                </li> */}
                                {/* <li>
                                    <a onClick={handleRoutingOnDiffPage} href="/#gallery">Gallery</a>
                                </li> */}
                               
                                <li>
                                    <a onClick={handleRoutingOnDiffPage} href="/#testi">Testimonial</a>
                                </li>
                                <li>
                                    <a href="/medymate-healthcare-for-Schools-Colleges">Medymate</a>
                                </li>
                                <li>
                                    <NavLink className="nav_news" to="/medytalk-health-webinars-discussions" > <img alt='logo' className='img-fluid header-image' src="assets/img/medytalk-heading-logo.png" />
                                    </NavLink>
                                </li>
                                <li>
                                    <a onClick={handleRoutingOnDiffPage} href="/#supported">Our Partners</a>
                                </li>
                                <li>
                                    <a onClick={handleRoutingOnDiffPage} href="/#contact-info">Contact Us</a>
                                </li>
                                {/* <li>
                                    <a href="#join-us">Join Us</a>
                                </li> */}
                                {/* <li>
                                    <a href=" ">Blogs</a>
                                </li> */}
                            </ul>
                        </div>
                        <div className="btns-box" onClick={() => handleDropList()}>
                            <div className="request-btn">
                                <a target={"_blank"} className="btn-1">Login <i className="fa fa-caret-down" aria-hidden="true" ></i></a>
                                {dorpList && (
                                    <ul className="dropdown-menu d-block dropdown-login">
                                        <li className="dropdown-item" >
                                            <a className='linkclr-header-dropdown' href="https://vle.medyseva.com/" target="_blank" previewlistener="true">Medysevak</a>
                                        </li>
                                        <li className="dropdown-item" >
                                            <a className='linkclr-header-dropdown' target="_blank" href="https://api.medyseva.com/medyseva-partners/signin" previewlistener="true">Partner</a>
                                        </li>
                                        <li className="dropdown-item" >
                                            <a className='linkclr-header-dropdown' href="https://api.medyseva.com/medyseva_clinic/login" target="_blank" previewlistener="true">Doctor</a>
                                        </li>
                                        <li className="dropdown-item" >
                                            <a className='linkclr-header-dropdown' href="http://lmh.vle.medyseva.com/" target="_blank" previewlistener="true">LMH</a>
                                        </li>
                                        <li className="dropdown-item" >
                                            <a className='linkclr-header-dropdown' href="http://smhrc.vle.medyseva.com/" target="_blank" previewlistener="true">SMHRC</a>
                                        </li>
                                        <li className="dropdown-item" >
                                            <a className='linkclr-header-dropdown' href="http://alliswell.vle.medyseva.com/" target="_blank" previewlistener="true">AIWMSH</a>
                                        </li>
                                    </ul>
                                )}

                            </div>
                        </div>
                        <div className="menu-btn-box d-xl-none">
                            <button type="button" className="round-btn"><i className="fas fa-bars" /></button>
                        </div>
                    </div>
                </div>
            </header>
            {/* <div className="side-menu-area">
                <div className="side-menu-box">
                    <div className="side-menu">
                        <div className="header">
                            <div className="logo">
                                <a href="index.html">
                                    <img src="assets/img/logo/logo-red.png" alt="logo"/>
                                </a>
                            </div>
                            <div className="close-btn">
                                <button type="button"><i className="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div className="navs">
                            <ul>
                                <li>
                                    <a>About Us</a>
                                    <div className="sub-menu">
                                        <ul>
                                            <li>
                                                <a href="">one</a>
                                            </li>
                                            <li>
                                                <a href="">two</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a>Services</a>
                                    <div className="sub-menu">
                                        <ul>
                                            <li>
                                                <a href="">one</a>
                                            </li>
                                            <li>
                                                <a href="">two</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="">Join Us</a>
                                </li>
                                <li>
                                    <a href="">Blogs</a>
                                </li>
                            </ul>
                        </div>
                        <div className="request-btn">
                            <button type="button" className="btn-1">Login</button>
                        </div>
                    </div>
                </div>
                <div className="overlay"></d                                                                                                                                                                  iv>
            </div> */}
        </>
    )
}

export default Header